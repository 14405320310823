<template>
  <div class="header-right-root">
    <div>
      <search class="search-comp" />
    </div>
    <div>
      <user-module />
    </div>
     <!-- <div class="col-xlg-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
       <user-module />
      <search width="220px"/>
      
    </div> -->
  </div>
</template>

<script>
import { EventBus } from "@/main";
import { mapGetters } from "vuex";

export default {
  name: "headerRight",
  computed:{...mapGetters(["subscriberId"])},
  components: {
    search: () =>
      import(
        /* webpackChunkName: "search" */ "@/components/Header/right/search.vue"
      ),
    "user-module": () =>
      import(
        /* webpackChunkName: "userModule" */ "@/components/Header/right/userModule.vue"
      )
  }
};
</script>
<style lang="scss" scoped>
@import "~sass/modules/_mediaQueries.scss";


.header-right-root{
  display: flex;
  flex-flow:row nowrap;
  justify-content: right;
}

.search-comp{
  margin-right:20px;
}

@include breakpoint(max600) {
  .login {
    padding: 0px;
  }
}
</style>